.dropzone-ui.drag .av-dropzone-label *, .dropzone-ui.drag .dz-ui-footer {
  color: #7B569B;
}

.dropzone-ui-layer.drag  {
  border-color: #7B569B;
}

.dropzone-ui .dz-ui-footer {
  width: 100% !important;
}