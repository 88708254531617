.avtable-no-results {
    text-align: center;
    margin-top: 35px
}
.avtable-cell-tooltip {
    font-size: 13px;
}
/* Default cell styles: */
.avtable-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}