.error.red {
    color: #AC2929;
}
.med-width-form, div.med-width-form {
    max-width: 992px;
}
.av-false-anchor { 
    color: #7b569b;
    transition: all .3s;
    text-decoration: none;
    cursor: pointer;
}